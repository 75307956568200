import http from '@/utils/http'

// 平板信息列表
export const gianListPadInfo = p => http.post('/mortconc/tabletcs/list', p)

// 平板信息单条
export const getOnePadInfo = p => http.post('/mortconc/tabletcs/one', p)

// 平板信息调整(新增或修改)
export const setPadInfo = p => http.post('/mortconc/tabletcs/set', p)

// 平板信息删除
export const deletePadInfo = p => http.post('/mortconc/tabletcs/del', p)

// 平板-料罐信息列表
export const gianListPadTankInfo = p => http.post('/mortconc/tabletTankcs/list', p)

// 平板-料罐信息单条
export const getOnePadTankInfo = p => http.post('/mortconc/tabletTankcs/one', p)

// 平板-料罐信息调整(新增或修改)
export const setPadTankInfo = p => http.post('/mortconc/tabletTankcs/set', p)

// 平板-料罐信息删除
export const deletePadTankInfo = p => http.post('/mortconc/tabletTankcs/del', p)
